import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { debugContextDevtool } from 'react-context-devtool';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';

import APOLLO_CLIENT from '~config';
import ROUTES from '~routes';

import 'reset-css';
import '~styles.scss';

import MainContent from '~components/MainContent';
import Header from '~components/Header';
import { msalConfig } from './authConfig';

// eslint-disable-next-line import/prefer-default-export
export const msalInstance = new PublicClientApplication(msalConfig);

const container = document.getElementById('app');

ReactDOM.render(
  <MsalProvider instance={msalInstance}>
    <ApolloProvider client={APOLLO_CLIENT}>
      <Router>
        <Header />
        <MainContent>
          <Switch>
            {ROUTES.map((x) => (<Route exact key={window.location.pathname} {...x} />))}
          </Switch>
        </MainContent>
      </Router>
    </ApolloProvider>
  </MsalProvider>,
  container,
);

debugContextDevtool(container, { disable: false });
