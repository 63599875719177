import React, {
  useState, useEffect, useContext, ChangeEvent,
} from 'react';
import { useMutation } from '@apollo/client';

import {
  GridCol, Grid, Input, Heading, Fieldset, Button,
} from '@flixbus/honeycomb-react';

import withNotifications, { NotificationData } from '~components/Common/WithNotifications';

import { setDefaultValues, setDefaultValuesVariables } from '~graphql/DefaultValues/__generated__/setDefaultValues';

import SET_DEFAULT_VALUES from '~graphql/DefaultValues';

import { DefaultValuesContext } from '~store/DefaultValues';

import onInputChange from '~services/onInputChange';
import CurrentLangcode from '~components/DefaultValues/CurrentLangcode';
import { ActionTypes } from '~store/DefaultValues/types';
import getDefaultValues from '~graphql/getDefaultValues';
import CurrentProject from '../CurrentProject';

import Metatags from './Metatags';
import CurrentTemplate from '../CurrentTemplate';

export interface PageForm_DefaultValues {
  handleNotifications?(data: NotificationData): void;
}

const DefaultValuesForm: React.FC<PageForm_DefaultValues> = (
  { handleNotifications }: PageForm_DefaultValues,
) => {
  const [submitted, setSubmitted] = useState(false);
  const {
    state:
    {
      langcode,
      headline,
      project,
      template,
      metatags: { title, description, robots },
    },
    dispatch,
  } = useContext(DefaultValuesContext);

  const [submitDefaultValues, { data, error, loading }] = useMutation<
    setDefaultValues, setDefaultValuesVariables
  >(SET_DEFAULT_VALUES, {
    refetchQueries: [{
      query: getDefaultValues,
      variables: {
        langcode,
        project,
        type: template,
        template,
      },
    }],
    awaitRefetchQueries: true,
    onQueryUpdated: (observable) => observable.refetch(),
  });

  useEffect(() => {
    handleNotifications({
      data, error, submitted,
    });
  }, [data, error]);

  return (
    <Grid>
      <GridCol size={4}>
        <CurrentLangcode />
      </GridCol>
      <GridCol size={4}>
        <CurrentProject />
      </GridCol>
      <GridCol size={4}>
        <CurrentTemplate />
      </GridCol>
      <GridCol size={6}>
        <Metatags />
      </GridCol>
      <GridCol size={6}>
        <Heading size={2}>
          Headline
        </Heading>
        <Fieldset>
          <Input
            id="headline"
            label="Headline"
            value={headline}
            onChange={((e: ChangeEvent<HTMLInputElement>) => onInputChange(e, () => dispatch({
              type: ActionTypes.SetHeadline,
              value: e.target.value,
            })))}
          />
          {/* Fieldset adds a margin top when there's more than 2 children elements */}
          <div />
        </Fieldset>
      </GridCol>
      <GridCol size={12}>
        <Button
          loading={loading}
          appearance="primary"
          onClick={() => {
            setSubmitted(true);
            submitDefaultValues(
              {
                variables: {
                  title,
                  description,
                  robots,
                  langcode,
                  headline,
                  project,
                  template,
                },
              },
            );
          }}
        >
          Submit
        </Button>
      </GridCol>
    </Grid>
  );
};

export default withNotifications(DefaultValuesForm);
