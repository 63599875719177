import { gql } from '@apollo/client';

export default gql`
  mutation addCityGuide(
    $pageID: String!,
    $fieldValue: String!,
    $fieldName: String!,
  ) {
    setPageTranslationFieldValue(input:{
      pageTranslationKey: $pageID
      value: $fieldValue
      name: $fieldName
  })
}`;
