import React, { useContext } from 'react';
import {
  Heading, Input, Fieldset, Radio, Legend,
  Textarea,
} from '@flixbus/honeycomb-react';

import { PageFormContext } from '~store/PageForm';

import { ActionTypes } from '~store/PageForm/types';

const MetatagsForm: React.FC = () => {
  const {
    state:
    {
      metatags: { title, description, robots },
    },
    dispatch,
  } = useContext(PageFormContext);

  return (
    <>
      <Heading size={2}>
        Metatags
      </Heading>
      <Fieldset>
        <Input
          id="metatag_title"
          type="text"
          label="Title"
          value={title || ''}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => dispatch({
            type: ActionTypes.SetMetatagTitle,
            value: e.target.value,
          })}
        />
        <Textarea
          id="metatag_description"
          type="text"
          label="Description"
          value={description || ''}
          onChange={
        (e: React.ChangeEvent<HTMLInputElement>) => dispatch({
          type: ActionTypes.SetMetatagDescription,
          value: e.target.value,
        })
      }
        />
        <Legend>Robots</Legend>
        <Radio
          label="Follow"
          id="robots_follow"
          name="robots"
          value="index, follow"
          key="robots-follow"
          checked={robots === 'index, follow'}
          onChange={
            (e: React.ChangeEvent<HTMLInputElement>) => dispatch({
              type: ActionTypes.SetMetatagRobots,
              value: e.target.value,
            })
          }
        />
        <Radio
          label="No Follow"
          id="robots_nofollow"
          name="robots"
          value="noindex, nofollow"
          key="robots-nofollow"
          checked={robots === 'noindex, nofollow'}
          onChange={
            (e: React.ChangeEvent<HTMLInputElement>) => dispatch({
              type: ActionTypes.SetMetatagRobots,
              value: e.target.value,
            })
          }
        />
      </Fieldset>
    </>
  );
};

export default MetatagsForm;
