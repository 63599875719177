import React, { useEffect, useContext, useState } from "react";
import { useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";

import { Grid, GridCol, Button } from "@flixbus/honeycomb-react";

import withNotifications, {
  NotificationData,
} from "~components/Common/WithNotifications";

import SET_CUSTOM_VALUES from "~graphql/PageForm_CustomValues";
import SET_CITY_GUIDE from "~graphql/PageForm_CustomValues/setCityGuide";
import ADD_CITY_GUIDE from "~graphql/PageForm_CustomValues/addCityGuide";

// import { PageByKey_DefaultValues } from '~graphql/getPageByKey/defaultValues';
import {
  setCustomValues,
  setCustomValuesVariables,
} from "~graphql/PageForm_CustomValues/__generated__/setCustomValues";
import { ActionTypes } from "~store/PageForm/types";
import { PageFormContext } from "~store/PageForm";

// import Headline from './Headline';
import {
  setPageTranslationFieldValue,
  setPageTranslationFieldValueVariables,
} from "~graphql/PageForm_CustomValues/__generated__/setPageTranslationFieldValue";
import {
  addCityGuide,
  addCityGuideVariables,
} from "~graphql/PageForm_CustomValues/__generated__/addCityGuide";
import MetatagsForm from "./Metatags";
// import CityGuide from './CityGuide';
import Headline from "./Headline";
import CityGuide from "./CityGuide";

export interface PageForm_CustomValues {
  handleNotifications?(data: NotificationData): void;
}

const CustomValuesForm: React.FC<PageForm_CustomValues> = ({
  handleNotifications,
}: PageForm_CustomValues) => {
  const { key } = useParams();
  const translationOfKey = key.split("@")[0];
  const {
    state: {
      langcode,
      template,
      metatags: { title, description, robots },
      headline,
      path,
      translations,
      status,
      permanent,
      fields,
      project,
    },
    dispatch,
  } = useContext(PageFormContext);
  const [submitted, setSubmitted] = useState(false);
  const pageValues = translations.filter((x) => x.langcode === langcode)[0];
  const [submitCustomValues, { data, error, loading }] = useMutation<
    setCustomValues,
    setCustomValuesVariables
  >(SET_CUSTOM_VALUES);
  const [setCityGuide, { data: guideData, error: guideError }] = useMutation<
    setPageTranslationFieldValue,
    setPageTranslationFieldValueVariables
  >(SET_CITY_GUIDE);
  const [addCityGuideMutation, { data: addGuideData, error: addguideError }] =
    useMutation<addCityGuide, addCityGuideVariables>(ADD_CITY_GUIDE);

  useEffect(() => {
    dispatch({
      type: ActionTypes.SetPageProperties,
      value: {
        ...pageValues,
      },
    });
  }, [langcode]);

  useEffect(() => {
    handleNotifications({
      data,
      error,
      submitted,
    });
    handleNotifications({
      data: guideData,
      error: guideError,
      submitted,
    });
    handleNotifications({
      data: addGuideData,
      error: addguideError,
      submitted,
    });
  }, [data, guideData, addGuideData, guideError, addguideError, error]);

  return (
    <Grid>
      <GridCol size={6}>
        <MetatagsForm />
      </GridCol>
      <GridCol size={6}>
        <Headline />
      </GridCol>
      <GridCol size={12}>
        <CityGuide />
      </GridCol>
      <GridCol size={12}>
        <Button
          loading={loading}
          appearance="primary"
          onClick={() => {
            setSubmitted(true);
            submitCustomValues({
              variables: {
                pageID: key,
                translationOfKey,
                path,
                title,
                template,
                description,
                robots,
                langcode,
                status,
                permanent,
                headlineValue: headline,
                project,
              },
            });
            if (fields && fields[0]) {
              if (fields[0]._key === "tmp") {
                addCityGuideMutation({
                  variables: {
                    pageID: key,
                    fieldValue: fields[0].value,
                    fieldName: fields[0].name,
                  },
                });
              } else {
                fields.map((field) => {
                  if (field.name === "city_guide") {
                    setCityGuide({
                      variables: {
                        pageID: key,
                        fieldKey: field._key,
                        fieldValue: field.value,
                        fieldName: field.name,
                      },
                    });
                  }
                });
              }
            }
          }}
        >
          Submit
        </Button>
      </GridCol>
    </Grid>
  );
};

export default withNotifications(CustomValuesForm);
