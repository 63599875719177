import { Templates } from '~types/graphql-global-types';

export interface PageListContextType {
  langcode: string;
  project: string;
  count: number;
  offset: number;
  currentPage: number;
  template: Templates;
}

export enum ActionTypes {
  SetLangcode = 'SET_LANGCODE',
  SetCount = 'SET_COUNT',
  SetOffset = 'SET_OFFSET',
  SetCurrentPage = 'SET_CURRENT_PAGE',
  SetProject = 'SET_PROJECT',
  SetTemplate = 'SET_TEMPLATE',
}

type SetLangcodeAction = {
  type: ActionTypes.SetProject;
  value: string;
}

type SetTemplateAction = {
  type: ActionTypes.SetTemplate;
  value: Templates;
}

type SetProjectAction = {
  type: ActionTypes.SetLangcode;
  value: string;
}

type SetCountAction = {
  type: ActionTypes.SetCount;
  value: number;
}

type SetOffsetAction = {
  type: ActionTypes.SetOffset;
  value: number;
}

type SetCurrentPage = {
  type: ActionTypes.SetCurrentPage;
  value: number;
}

export type PageListActions =
  SetLangcodeAction |
  SetCountAction |
  SetCurrentPage |
  SetOffsetAction |
  SetProjectAction |
  SetTemplateAction;
