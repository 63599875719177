/* tslint:disable */
/* eslint-disable */

export enum PROJECTS {
  flixbus = 'flixbus',
  flixtrain = 'flixtrain',
  greyhound = 'greyhound',
  // 'flix',
}

export function projectStringToEnum(projectString: string): PROJECTS | undefined {
  // Check if the input string contains a slash '/'
  if (projectString.includes('/')) {
    const parts = projectString.split('/');
    if (parts.length === 2 && parts[0] === 'projects') {
      const projectName = parts[1];
      return getEnumValue(projectName);
    }
  } else {
    // If there's no '/', assume the input is just the project name
    return getEnumValue(projectString);
  }
  return undefined;
}

function getEnumValue(projectName: string): PROJECTS | undefined {
  const enumKeys = Object.keys(PROJECTS).filter((key) => isNaN(Number(key));
  const result = enumKeys.find((key) => PROJECTS[key] === projectName);
  return result ? PROJECTS[result] : undefined;
}