import { gql } from '@apollo/client';

export default gql`
  query CityPages($count: Int!, $offset: Int!, $langcode: String!, $template: Templates!, $project: String!) {
    pages: pageTranslations(template: $template, project: $project, langcode: $langcode, count: $count, offset: $offset) {
      template
      _key
      headline
      path
      langcode
      status
    }
  }
`;
