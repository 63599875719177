import React, { useContext } from 'react';
import {
  Heading, Input, Fieldset, Textarea,
} from '@flixbus/honeycomb-react';

import { DefaultValuesContext } from '~store/DefaultValues';

import { ActionTypes } from '~store/DefaultValues/types';

const MetatagsForm: React.FC = () => {
  const {
    state:
    {
      metatags: { title, description },
    },
    dispatch,
  } = useContext(DefaultValuesContext);

  return (
    <>
      <Heading size={2}>
        Metatags
      </Heading>
      <Fieldset>
        <Input
          id="metatag_title"
          type="text"
          label="Title"
          value={title}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => dispatch({
            type: ActionTypes.SetMetatagTitle,
            value: e.target.value,
          })}
          info="When the input is empty, it will take the value from Headline and append '| FlixBus'"
        />
        <Textarea
          id="metatag_description"
          label="Description"
          value={description}
          onChange={
        (e: React.ChangeEvent<HTMLInputElement>) => dispatch({
          type: ActionTypes.SetMetatagDescription,
          value: e.target.value,
        })
      }
        />
      </Fieldset>
    </>
  );
};

export default MetatagsForm;
