import React from 'react';

import PageList from '~components/PageList';
import { PageListContextProvider } from '~store/PageList';

const Home = () => (
  <PageListContextProvider>
    <PageList />
  </PageListContextProvider>
);

export default Home;
