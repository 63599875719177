import React, { useContext, useEffect } from 'react';
import {
  Box,
  Heading,
  Grid, GridCol,
  Spinner,
} from '@flixbus/honeycomb-react';

import {
  PageByKeyVariables, PageByKey,
} from '~graphql/getPageByKey/__generated__/PageByKey';

import CurrentLangcode from '~components/Page/CurrentLangcode';
import { PageFormContext } from '~store/PageForm';
import { ActionTypes } from '~store/PageForm/types';

import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import GET_PAGE_BY_KEY from '~graphql/getPageByKey';
import { projectStringToEnum } from '~types/projects';
import CustomValuesForm from './CustomValuesForm';
import Status from './CustomValuesForm/Status';

// import Connections from './Connections';

const PageForm: React.FC = () => {
  const {
    state:
    {
      translations,
      langcode,
    },
    dispatch,
  } = useContext(PageFormContext);
  const { key }: Params = useParams();
  const pageKey = key.split('@')[0];
  const {
    data, loading, error,
  } = useQuery<PageByKey, PageByKeyVariables>(GET_PAGE_BY_KEY, {
    variables: {
      key: pageKey,
    },
  });

  useEffect(() => {
    if (!loading && data) {
      const langKey = key.split('@')[1] || data.pageByKey.translations[0].langcode;
      const pageValues = translations.filter(
        (x) => x.langcode === langKey,
      )[0];
      dispatch({
        type: ActionTypes.SetLangcode,
        value: langKey,
      });
      dispatch({
        type: ActionTypes.SetTranslations,
        value: data.pageByKey.translations,
      });
      dispatch({
        type: ActionTypes.SetTemplate,
        value: data.pageByKey.template,
      });
      dispatch({
        type: ActionTypes.SetProject,
        value: projectStringToEnum(data.pageByKey.project),
      });
      // dispatch({
      //   type: ActionTypes.SetConnections,
      //   value: data.pageByKey.Places[0].connections,
      // });
      dispatch({
        type: ActionTypes.SetPageProperties,
        value: { ...pageValues },
      });
    }
  }, [data]);

  if (error) {
    return (<div>{error.message}</div>);
  }

  return (
    <Box>
      {data && langcode !== ''
        && (
          <Grid>
            <GridCol size={6}>
              <Heading size={1}>
                Editing page:
                {' '}
                {data.pageByKey.translations.filter((x) => x.langcode === langcode)[0].path}
              </Heading>
            </GridCol>
            <GridCol size={6}>
              <Status />
            </GridCol>
          </Grid>
        )}
      {loading
        ? <Spinner />
        : (
          <>
            <CurrentLangcode />
            <CustomValuesForm />
            {/* <Connections /> */}
          </>
        )}

    </Box>
  );
};

interface Params {
  key: string;
}

export default PageForm;
