import React, { useContext } from "react";
import ReactQuill from "react-quill"; // ES6
import { Fieldset, Heading, Button } from "@flixbus/honeycomb-react";

import { PageFormContext } from "~store/PageForm";
import { ActionTypes } from "~store/PageForm/types";

import "react-quill/dist/quill.snow.css";
import { PageByKey_pageByKey_translations_fields } from "~graphql/getPageByKey/__generated__/PageByKey";

import * as styles from "../place.scss";

const CityGuide: React.FC = () => {
  const {
    state: { fields },
    dispatch,
  } = useContext(PageFormContext);

  function noCityGuideFunc() {
    let noCityGuide = true;
    fields.map(({ name }) => {
      if (name === "city_guide") {
        noCityGuide = false;
      }
    });
    return noCityGuide;
  }

  return (
    <>
      {fields && fields.length > -1 ? (
        <>
          {noCityGuideFunc() ? (
            <div className={styles.addGuideBtn}>
              <Button
                onClick={() => {
                  dispatch({
                    type: ActionTypes.SetFields,
                    value: {
                      _key: "tmp",
                      __typename: "Field",
                      order: 9999,
                      name: "city_guide",
                      value: "",
                    },
                  });
                }}
              >
                Add city guide
              </Button>
            </div>
          ) : (
            <Fieldset>
              <Heading size={2}>Fields</Heading>
              {fields.map(({ name, value, _key, ...rest }) => {
                if (name === "city_guide") {
                  return (
                    <div key={_key}>
                      <Heading size={3}>{name}</Heading>
                      <ReactQuill
                        value={value}
                        className={styles.editor}
                        scrollingContainer=".editor"
                        onChange={(val) => {
                          const newVal: PageByKey_pageByKey_translations_fields =
                            {
                              ...rest,
                              _key,
                              name,
                              value: val,
                            };
                          dispatch({
                            type: ActionTypes.ChangeFields,
                            value: newVal,
                          });
                        }}
                      />
                    </div>
                  );
                }
              })}
            </Fieldset>
          )}
        </>
      ) : (
        <div className={styles.addGuideBtn}>
          <Button
            onClick={() => {
              dispatch({
                type: ActionTypes.SetFields,
                value: {
                  _key: "tmp",
                  __typename: "Field",
                  order: 9999,
                  name: "city_guide",
                  value: "",
                },
              });
            }}
          >
            Add city guide
          </Button>
        </div>
      )}
    </>
  );
};

export default CityGuide;
