import {
  DefaultValuesContextType, ActionTypes, DefaultValuesActions, LAST_CHANGED_LANGCODE,
} from './types';

const reducer = (state: DefaultValuesContextType, action: DefaultValuesActions) => {
  switch (action.type) {
    case ActionTypes.SetLangcode:
      sessionStorage.setItem(LAST_CHANGED_LANGCODE, action.value);
      return {
        ...state,
        langcode: action.value,
      };
    case ActionTypes.SetProject:
      return {
        ...state,
        project: action.value,
      };
    case ActionTypes.SetTemplate:
      return {
        ...state,
        template: action.value,
      };
    case ActionTypes.SetMetatagTitle:
      return {
        ...state,
        metatags: {
          ...state.metatags,
          title: action.value,
        },
      };
    case ActionTypes.SetMetatagDescription:
      return {
        ...state,
        metatags: {
          ...state.metatags,
          description: action.value,
        },
      };
    case ActionTypes.SetMetatagRobots:
      return {
        ...state,
        metatags: {
          ...state.metatags,
          robots: action.value,
        },
      };
    case ActionTypes.SetMetatags:
      return {
        ...state,
        metatags: {
          ...action.value,
        },
      };
    case ActionTypes.SetHeadline:
      return {
        ...state,
        headline: action.value,
      };
    default:
      throw new Error('Action is not set');
  }
};

export default reducer;
