import { PageListContextType, ActionTypes, PageListActions } from './types';

const reducer = (state: PageListContextType, action: PageListActions) => {
  switch (action.type) {
    case ActionTypes.SetLangcode:
      return {
        ...state,
        langcode: action.value,
      };
    case ActionTypes.SetProject:
      return {
        ...state,
        project: action.value,
      };
    case ActionTypes.SetTemplate:
      return {
        ...state,
        template: action.value,
      };
    case ActionTypes.SetCount:
      return {
        ...state,
        count: action.value,
      };
    case ActionTypes.SetOffset:
      return {
        ...state,
        offset: action.value,
      };
    case ActionTypes.SetCurrentPage:
      return {
        ...state,
        currentPage: action.value,
      };
    default:
      throw new Error('Action is not set');
  }
};

export default reducer;
