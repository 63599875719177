import React, { useContext } from 'react';

import {
  Heading, Select, Fieldset,
} from '@flixbus/honeycomb-react';

import { ActionTypes } from '~store/DefaultValues/types';
import { DefaultValuesContext } from '~store/DefaultValues';
import { Templates } from '~types/graphql-global-types';

const CurrentTemplate: React.FC = () => {
  const { state: { template }, dispatch } = useContext(DefaultValuesContext);

  return (
    <Fieldset>
      <Heading size={2}>
        Current Template
      </Heading>

      <Select
        id="langcodes"
        label="Current template"
        defaultValue={template}
        options={Object.keys(Templates)
          .filter((x) => x !== 'city_hub' && x !== 'page404')
          .map((x) => ({
            value: x,
            displayValue: x.toLocaleUpperCase(),
          }))}
        onChange={
          (e: React.ChangeEvent<HTMLInputElement>) => dispatch({
            type: ActionTypes.SetTemplate,
            value: Templates[e.target.value],
          })
        }
      />
    </Fieldset>
  );
};

export default CurrentTemplate;
