import { gql } from '@apollo/client';

export default gql`
  mutation setPageTranslationFieldValue(
    $fieldKey: String!,
    $pageID: String!,
    $fieldValue: String!,
    $fieldName: String!,
  ) {
    setPageTranslationFieldValue(input:{
      fieldKey: $fieldKey
      pageTranslationKey: $pageID
      value: $fieldValue
      name: $fieldName
  })
}`;
