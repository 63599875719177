import React, { useState } from 'react';

import {
  Notification, NotificationContainer,
} from '@flixbus/honeycomb-react';

import { ApolloError } from '@apollo/client';
import { setDefaultValues } from '~graphql/DefaultValues/__generated__/setDefaultValues';
import { setCustomValues } from '~graphql/PageForm_CustomValues/__generated__/setCustomValues';
import { setPageTranslationFieldValue } from '~graphql/PageForm_CustomValues/__generated__/setPageTranslationFieldValue';

import { PageForm_DefaultValues } from '~components/DefaultValues/DefaultValuesForm';
import { PageForm_CustomValues } from '~components/Page/CustomValuesForm';

type WithNotificationsProps = PageForm_DefaultValues | PageForm_CustomValues;

export interface NotificationData {
  data: setDefaultValues | setCustomValues | setPageTranslationFieldValue;
  error: ApolloError;
  submitted: boolean;
}

const withNotifications = (Component: React.ComponentType<WithNotificationsProps>) => (
  props: WithNotificationsProps,
) => {
  const [submissions, setSubmissions] = useState([]);

  const handleNotifications = ({ data, error, submitted }: NotificationData) => {
    const mutated = data !== undefined || error !== undefined;
    if (submitted && mutated) {
      setSubmissions((prev) => prev.concat(
        <Notification key={submissions.length} toast appearance={error === undefined ? 'success' : 'error'}>
          { error === undefined ? 'Submission succesful! 🎉' : error.message }
        </Notification>,
      ));
    }
  };

  return (
    <>
      <Component {...props} handleNotifications={handleNotifications} />
      {submissions.length > 0 && (
      <NotificationContainer position="bottom-right">
        {submissions}
      </NotificationContainer>
      )}
    </>
  );
};

export default withNotifications;
