import { gql } from '@apollo/client';

export default gql`
  mutation setCustomValues(
    $pageID: String!,
    $translationOfKey: String!
    $path: String!,
    $langcode: String!,
    $title: String!,
    $description: String!,
    $template: Templates!,
    $robots: String!,
    $status: Int!,
    $permanent: Int!,
    $headlineValue: String!,
    $project: String!,
  ) {
    setMetatags(input: {
      page: $pageID
      project: $project
      langcode: $langcode
      template: $template,
      title: $title
      description: $description
      robots: $robots
    })
    setPageTranslation(input: {
      translationOfKey: $translationOfKey
      path: $path,
      langcode: $langcode
      headline: $headlineValue
      status: $status
      permanent: $permanent
    })
  }
`;
