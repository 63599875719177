import React, { createContext, useReducer, Dispatch } from 'react';

import { PROJECTS } from '~types/projects';
import { Templates } from '~types/graphql-global-types';
import { PageFormContextType, PageFormActions } from './types';

import reducer from './reducer';

interface PageFormContextProviderProps {
  children: React.ReactChild | React.ReactChildren,
}

const initialState: PageFormContextType = {
  langcode: '',
  path: '',
  globalSettingsActive: false,
  headline: '',
  status: 0,
  template: Templates.city,
  project: PROJECTS.flixbus,
  translations: [],
  permanent: 0,
  metatags: {
    __typename: 'MetatagsPattern',
    description: '',
    robots: '',
    langcode: '',
    title: '',
  },
  fields: [],
};

const PageFormContext = createContext<{
  state: PageFormContextType;
  dispatch: Dispatch<PageFormActions>;
  }>({
    state: initialState,
    dispatch: () => null,
  });

const PageFormContextProvider: React.FC = ({ children }: PageFormContextProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <PageFormContext.Provider value={{ state, dispatch }}>{children}</PageFormContext.Provider>
  );
};

const PageFormContextConsumer = PageFormContext.Consumer;

export {
  initialState, PageFormContext, PageFormContextProvider, PageFormContextConsumer,
};
