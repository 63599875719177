import { PageFormContextType, ActionTypes, PageFormActions } from './types';

const reducer = (state: PageFormContextType, action: PageFormActions) => {
  switch (action.type) {
    case ActionTypes.SetLangcode:
      return {
        ...state,
        langcode: action.value,
      };
    case ActionTypes.SetMetatagTitle:
      return {
        ...state,
        metatags: {
          ...state.metatags,
          title: action.value,
        },
      };
    case ActionTypes.SetTranslations:
      return {
        ...state,
        translations: action.value,
      };
    case ActionTypes.SetTemplate:
      return {
        ...state,
        template: action.value,
      };
    case ActionTypes.SetProject:
      return {
        ...state,
        project: action.value,
      };
    case ActionTypes.SetMetatagDescription:
      return {
        ...state,
        metatags: {
          ...state.metatags,
          description: action.value,
        },
      };
    case ActionTypes.SetMetatagRobots:
      return {
        ...state,
        metatags: {
          ...state.metatags,
          robots: action.value,
        },
      };
    case ActionTypes.SetMetatags:
      return {
        ...state,
        metatags: {
          ...action.value,
        },
      };
    case ActionTypes.SetHeadline:
      return {
        ...state,
        headline: action.value,
      };
    case ActionTypes.SetStatus:
      return {
        ...state,
        status: action.value,
      };
    case ActionTypes.SetPermanent:
      return {
        ...state,
        permanent: action.value,
      };
    case ActionTypes.SetPageProperties:
      if (action.value.metatags == null) {
        // eslint-disable-next-line no-param-reassign
        action.value.metatags = {
          __typename: 'MetatagsPattern',
          title: '',
          robots: '',
          langcode: '',
          description: '',
        };
      }
      return {
        ...state,
        ...action.value,
      };
    case ActionTypes.SetFields: {
      if (state.fields && state.fields.length > 0) {
        return {
          ...state,
          fields: [action.value, ...state.fields],
        };
      }
      return {
        ...state,
        fields: [{
          ...action.value,
        }],
      };
    }
    case ActionTypes.ChangeFields: {
      return {
        ...state,
        fields: state.fields.map((x) => (x._key === action.value._key ? {
          ...x,
          value: action.value.value,
        } : x)),
      };
    }
    default:
      throw new Error('Action is not set');
  }
};

export default reducer;
