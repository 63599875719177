import React, { useContext, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { DefaultValuesContext, initialState } from '~store/DefaultValues';

import GET_DEFAULT_VALUES from '~graphql/getDefaultValues';

import { getDefaultValues, getDefaultValuesVariables } from '~graphql/getDefaultValues/__generated__/getDefaultValues';
import { ActionTypes } from '~store/DefaultValues/types';

import DefaultValuesForm from './DefaultValuesForm';

const DefaultValuesPage = () => {
  const {
    state: {
      langcode,
      project,
      template,
    },
    dispatch,
  } = useContext(DefaultValuesContext);

  const {
    data,
    loading,
  } = useQuery<getDefaultValues, getDefaultValuesVariables>(GET_DEFAULT_VALUES, {
    variables: {
      langcode,
      project,
      type: template,
      template,
    },
    errorPolicy: 'all',
  });

  useEffect(() => {
    if (data && !loading) {
      const { headline, pageMetatags } = data;

      const metatags = pageMetatags ? {
        __typename: pageMetatags[0].__typename,
        title: pageMetatags[0].title ?? '',
        description: pageMetatags[0].description ?? '',
        robots: pageMetatags[0].robots,
        langcode: pageMetatags[0].langcode,
      } : initialState.metatags;

      dispatch({
        type: ActionTypes.SetHeadline,
        value: headline?.value || '',
      });

      dispatch({
        type: ActionTypes.SetMetatags,
        value: metatags,
      });
    }
  }, [langcode, project, data, template]);

  return (
    <DefaultValuesForm />
  );
};

export default DefaultValuesPage;
