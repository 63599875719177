import { gql } from '@apollo/client';

export default gql`
  mutation setDefaultValues(
    $langcode: String!,
    $title: String!,
    $description: String!,
    $robots: String!,
    $headline: String!,
    $project: String!,
    $template: Templates!,
  ) {
    setMetatags(input: {
      project: $project
      langcode: $langcode
      template: $template
      title: $title
      description: $description
      robots: $robots
    })
    setDefaultValue(input:{
      langcode: $langcode
      type: $template
      project: $project
      fieldName: "headline"
      value: $headline
    })
  }
`;
