import { gql } from '@apollo/client';

export default gql`
  query getDefaultValues($type: String!, $langcode: String!, $project: String!, $template: Templates!) {
    path: defaultValue(
      fieldName: "path",
      project: $project,
      langcode: $langcode,
      type: $type) {
        value
    }
    headline: defaultValue(
      fieldName: "headline",
      project: $project,
      langcode: $langcode,
      type: $type) {
        value
    }
    pageMetatags(
    project: $project,
    template: $template
    langcode: $langcode
    count:1
    offset:0
  ) {
      description
      title
      template
      robots
      langcode
    }
  }
`;
