import React, { useContext, useEffect, useState } from "react";
import axios from "axios";

import { Heading, Select, Fieldset } from "@flixbus/honeycomb-react";

import { ActionTypes, LAST_CHANGED_LANGCODE } from "~store/DefaultValues/types";
import { DefaultValuesContext } from "~store/DefaultValues";

const CurrentLangcode: React.FC = () => {
  const {
    state: { project, langcode },
    dispatch,
  } = useContext(DefaultValuesContext);
  const [languages, setLanguages] = useState<GetLanguages_languages[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `${process.env.DRUPAL9_ENDPOINT}?query={languages(project:"${project}", includeInactive:true){name langcode}}`
        );
        setLanguages(res.data.data.languages);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [project]);

  useEffect(() => {
    if (languages.length > 0) {
      const lastIndex = languages.findIndex(
        (e) => e.langcode === sessionStorage.getItem(LAST_CHANGED_LANGCODE)
      );
      const index = lastIndex > -1 ? lastIndex : 0;
      dispatch({
        type: ActionTypes.SetLangcode,
        value: languages[index].langcode,
      });
    }
  }, [languages]);

  return (
    <Fieldset>
      <Heading size={2}>Current Langcode</Heading>
      {!loading && (
        <Select
          id="langcodes"
          label="Current langcode"
          value={langcode}
          options={languages.map((x) => ({
            value: x.langcode,
            displayValue: x.langcode,
          }))}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            dispatch({
              type: ActionTypes.SetLangcode,
              value: e.target.value,
            })
          }
        />
      )}
    </Fieldset>
  );
};

export default CurrentLangcode;
