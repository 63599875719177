import { NavLink } from 'react-router-dom';

const NAVIGATION = [{
  content: 'Landing Pages',
  href: '/',
  exact: true,
  RouterLink: NavLink,
}, {
  content: 'Default Values',
  href: '/default-values',
  exact: true,
  RouterLink: NavLink,
}, {
  content: 'Token List',
  href: '/tokens',
  exact: true,
  RouterLink: NavLink,
}];

export default NAVIGATION;
