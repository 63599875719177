import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Heading, Select, Fieldset } from "@flixbus/honeycomb-react";

import { ActionTypes } from "~store/PageList/types";
import { initialState, PageListContext } from "~store/PageList";

const Filters: React.FC = () => {
  const {
    state: { langcode, project },
    dispatch,
  } = useContext(PageListContext);

  const [languages, setLanguages] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        const response = await axios.get(
          `${process.env.DRUPAL9_ENDPOINT}?query={languages(project:"${project}", includeInactive:true){name langcode}}`
        );
        // console.log(response.data.data.languages);
        setLanguages(response.data.data.languages);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchLanguages();
  }, [project]);

  useEffect(() => {
    if (!loading) {
      if (languages.length > 0 && languages[0].langcode === langcode) {
        return;
      }
      dispatch({
        type: ActionTypes.SetLangcode,
        value: languages[0].langcode,
      });
    }
  }, [languages, project, loading]);

  return (
    <Fieldset>
      <Heading size={2}>Current Langcode</Heading>
      {!loading && (
        <Select
          id="langcodes"
          label="Current langcode"
          value={langcode}
          options={languages.map((x) => ({
            value: x.langcode,
            displayValue: x.langcode,
          }))}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            dispatch({
              type: ActionTypes.SetCount,
              value: initialState.count,
            });
            dispatch({
              type: ActionTypes.SetOffset,
              value: initialState.offset,
            });
            dispatch({
              type: ActionTypes.SetCurrentPage,
              value: initialState.currentPage,
            });
            dispatch({
              type: ActionTypes.SetLangcode,
              value: e.target.value,
            });
          }}
        />
      )}
    </Fieldset>
  );
};

export default Filters;
