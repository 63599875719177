import React from 'react';
import {
  AuthenticatedTemplate, UnauthenticatedTemplate, useMsal,
} from '@azure/msal-react';
import { Button } from '@flixbus/honeycomb-react';
import { loginRequest } from '../../authConfig';

import * as styles from './mainContent.scss';

interface MainContentProps {
  children: React.ReactChild | React.ReactChildren,
}

const handleLogin = (instance) => {
  instance.loginPopup(loginRequest).catch((e) => {
    // eslint-disable-next-line no-console
    console.error(e);
  });
};

const MainContent: React.FC = ({ children }: MainContentProps) => {
  const { instance } = useMsal();

  return (
    <main className={styles.container}>
      <AuthenticatedTemplate>
        {children}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Button variant="primary" onClick={() => handleLogin(instance)}>Sign in using Redirect</Button>
      </UnauthenticatedTemplate>
    </main>
  );
};

export default MainContent;
