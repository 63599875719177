import React from 'react';
import { Header as HcHeader, HeaderUserWidget } from '@flixbus/honeycomb-react';
import { useMsal } from '@azure/msal-react';

import NAVIGATION from './navigation';

import headerimg from './kingcron-cms.svg';

import * as styles from './header.scss';

const brand = {
  alt: 'kingcron-cms',
  image: headerimg,
  href: '/',
};

const Header = () => {
  const { accounts, inProgress } = useMsal();

  return (
    <HcHeader
      brand={brand}
      navigation={NAVIGATION}
      widget={(inProgress === 'none' && accounts.length > 0) && (
        <HeaderUserWidget
          url="/logout"
          text={`${accounts[0].name} | Logout`}
        />
      )}
      fixed={false}
      extraClasses={[styles.header, process.env.NODE_ENV === 'staging' ? styles.staging : ''].join(' ')}
    />
  );
};

export default Header;
