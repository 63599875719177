import React from 'react';
import { DefaultValuesContextProvider } from '~store/DefaultValues';

import DefaultValuesPage from '.';

const DefaultValuesPageContainer = () => (
  <DefaultValuesContextProvider>
    <DefaultValuesPage />
  </DefaultValuesContextProvider>
);

export default DefaultValuesPageContainer;
