import React, { useContext } from 'react';
import {
  Legend, Fieldset, Checkbox,
} from '@flixbus/honeycomb-react';

import { PageFormContext } from '~store/PageForm';

import { ActionTypes } from '~store/PageForm/types';

const Status: React.FC = () => {
  const {
    state:
      {
        status,
      },
    dispatch,
  } = useContext(PageFormContext);

  return (
    <>
      <Fieldset>
        <Legend>Published status</Legend>
        <Checkbox
          label="Published"
          id="published"
          disabled
          value="published"
          checked={status === 1}
          onChange={() => dispatch({
            type: ActionTypes.SetStatus,
            value: status === 1 ? 0 : 1,
          })}
        />
      </Fieldset>
    </>
  );
};

export default Status;
