import { gql } from '@apollo/client';

export default gql`
  query PageByKey($key: String!) {
    pageByKey(key: $key) {
      template
      project
      translations {
        path
        headline
        langcode
        permanent
        status
        metatags {
          title
          langcode
          description
          robots
        }
        fields {
          _key
          value
          name
          order
        }
      }
    }
  }
`;
