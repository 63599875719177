import React, { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';

const Logout = () => {
  const { instance, accounts } = useMsal();

  useEffect(() => {
    const logoutRequest = {
      account: instance.getAccountByHomeId(accounts[0].homeAccountId),
      postLogoutRedirectUri: window.location.origin,
    };
    instance.logoutRedirect(logoutRequest);
  }, []);

  return <div />;
};

export default Logout;
