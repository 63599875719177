import DefaultValuesPageContainer from '~components/DefaultValues/Container';
import Home from '~components/Home';
import Logout from '~components/Logout';
import Page from '~components/Page';
import TokenList from '~components/TokenList';

interface Route {
  path: String,
  component: Object,
}

const ROUTES: Route[] = [
  {
    path: '/',
    component: Home,
  },
  {
    path: '/page/:key',
    component: Page,
  },
  {
    path: '/default-values',
    component: DefaultValuesPageContainer,
  },
  {
    path: '/tokens',
    component: TokenList,
  },
  {
    path: '/logout',
    component: Logout,
  },
];

export default ROUTES;
