import React, { useContext } from 'react';
import {
  Heading, Input, Fieldset,
} from '@flixbus/honeycomb-react';

import { PageFormContext } from '~store/PageForm';

import { ActionTypes } from '~store/PageForm/types';

const Headline: React.FC = () => {
  const {
    state:
      {
        headline,
      },
    dispatch,
  } = useContext(PageFormContext);

  return (
    <>
      <Heading size={2}>
        Headlines
      </Heading>
      <Fieldset>
        <Input
          id="headline"
          type="text"
          label="H1"
          value={headline}
          onChange={
      (e: React.ChangeEvent<HTMLInputElement>) => dispatch({
        type: ActionTypes.SetHeadline,
        value: e.target.value,
      })
    }
        />
        <div />
      </Fieldset>
    </>
  );
};

export default Headline;
