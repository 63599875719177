import React, { createContext, useReducer, Dispatch } from 'react';

import { Templates } from '~types/graphql-global-types';
import { PageListContextType, PageListActions } from './types';

import reducer from './reducer';

interface PageListContextProviderProps {
  children: React.ReactChild | React.ReactChildren,
}

const initialState: PageListContextType = {
  langcode: 'de',
  project: 'flixbus',
  template: Templates.city,
  currentPage: 1,
  count: 100,
  offset: 0,
};

const PageListContext = createContext<{
  state: PageListContextType;
  dispatch: Dispatch<PageListActions>;
  }>({
    state: initialState,
    dispatch: () => null,
  });

const PageListContextProvider: React.FC = (
  { children }: PageListContextProviderProps,
) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <PageListContext.Provider value={{ state, dispatch }}>
      {children}
    </PageListContext.Provider>
  );
};

const PageListContextConsumer = PageListContext.Consumer;

export {
  initialState, PageListContext, PageListContextProvider, PageListContextConsumer,
};
