import React from 'react';

import { Table, Heading } from '@flixbus/honeycomb-react';

import * as styles from './tokenList.scss';

const TokenList = () => {
  const HEADERS: String[] = [
    'Token',
    'Description',
  ];

  return (
    <>
      <div style={{ marginBottom: '24px' }}>
        <Heading size={2}>City page</Heading>
        <Table
          headers={HEADERS}
          rows={
        [
          ['[lp_token:place_name]', 'Returns the name of the city (e.g. Berlin)'],
          ['[lp_token:place_slug]', 'Url of the city in latin characters (e.g. /muenchen)'],
        ]
      }
          extraClasses={styles.table}
        />
      </div>
      <div>
        <Heading size={2}>City Connection page</Heading>
        <Table
          headers={HEADERS}
          rows={
        [
          ['[lp_token:place_name_1]', 'Returns the name of the first city'],
          ['[lp_token:place_name_2]', 'Returns the name of the second city'],
          ['[lp_token:place_slug_1]', 'Returns the slug of the first city'],
          ['[lp_token:place_slug_2]', 'Returns the slug of the second city'],
        ]
      }
          extraClasses={styles.table}
        />
      </div>
    </>
  );
};

export default TokenList;
