import React from 'react';

import { Link } from 'react-router-dom';
import { ButtonArrow } from '@flixbus/honeycomb-react';

import { PageFormContextProvider } from '~store/PageForm';

import PageForm from './PageForm';

const Page: React.FC = () => (
  <PageFormContextProvider>
    <ButtonArrow RouterLink={Link} to="/" direction="back">Back to overview</ButtonArrow>
    <PageForm />
  </PageFormContextProvider>
);

export default Page;
