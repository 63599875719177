import React, { ChangeEvent, useContext, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';

import {
  Heading, ButtonArrow, Table, Box, Spinner,
  Pager,
  GridCol, Grid,
  Input,
} from '@flixbus/honeycomb-react';

import GET_ALL_CITY_PAGES from '~graphql/getAllCityPages';

import { CityPagesVariables, CityPages_pages, CityPages } from '~graphql/getAllCityPages/__generated__/CityPages';

import { PageListContext } from '~store/PageList';

import { ActionTypes } from '~store/PageList/types';

import onInputChange from '~services/onInputChange';
import CurrentLangcode from './CurrentLangcode';
import CurrentProject from './CurrentProject';
import CurrentTemplate from './CurrentTemplate';

import * as styles from './pageList.scss';

const HEADERS: String[] = [
  'Name',
  'Path',
  'Template',
  '',
];

const PageList = () => {
  const [pathFilter, setPathFilter] = useState('');
  const {
    state: {
      count,
      offset,
      langcode,
      currentPage,
      template,
      project,
    },
    dispatch,
  } = useContext(PageListContext);

  const transformPages = ({ pages }: CityPages) => pages
    .filter((x: CityPages_pages) => x.status === 1)
    .filter((x) => x.path.indexOf(pathFilter) > -1)
    .map(({ headline, path, _key, template: cityTemplate }: CityPages_pages) => [
      headline,
      path,
      cityTemplate,
      <ButtonArrow appearance="strong" RouterLink={Link} to={`/page/${_key}`}>Edit</ButtonArrow>,
    ]);

  const { data, loading } = useQuery<CityPages, CityPagesVariables>(GET_ALL_CITY_PAGES, {
    variables: {
      langcode,
      count,
      offset,
      template,
      project,
    },
  });

  const pages = data ? transformPages(data) : [];

  return (
    <div className={styles.container}>
      <Heading size={1}>
        Cities
      </Heading>
      <Box extraClasses={styles.box}>
        <Grid>
          <GridCol size={4}>
            <CurrentLangcode />
          </GridCol>
          <GridCol size={4}>
            <CurrentProject />
          </GridCol>
          <GridCol size={4}>
            <CurrentTemplate />
          </GridCol>
          <GridCol size={12}>
            <Heading size={3}>
              Filter by path
            </Heading>
            <Input
              id="path-filter"
              label="Path substring"
              type="text"
              onChange={((e: ChangeEvent<HTMLInputElement>) => onInputChange(e, setPathFilter))}
            />
          </GridCol>
        </Grid>
      </Box>
      {loading
        ? <Spinner />
        : (
          <>
            <Table rows={pages} headers={HEADERS} extraClasses={styles.table} />

            <Pager
              aria-label="Pagination"
              prevButton={
                currentPage < 2
                  ? null
                  : {
                    onClick: () => {
                      dispatch({
                        type: ActionTypes.SetOffset,
                        value: offset - count,
                      });
                      dispatch({
                        type: ActionTypes.SetCurrentPage,
                        value: currentPage - 1,
                      });
                    },
                  }
              }
              nextButton={{
                onClick: () => {
                  dispatch({
                    type: ActionTypes.SetOffset,
                    value: offset + count,
                  });
                  dispatch({
                    type: ActionTypes.SetCurrentPage,
                    value: currentPage + 1,
                  });
                },
              }}
              items={[
                {
                  text: currentPage.toString(),
                  active: true,
                },
              ]}
            />
          </>
        )}
    </div>
  );
};

export default PageList;
