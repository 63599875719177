import {
  PageByKey_pageByKey_translations_metatags, PageByKey_pageByKey_translations,
  PageByKey_pageByKey_translations_fields,
} from '~graphql/getPageByKey/__generated__/PageByKey';
import { Templates } from '~types/graphql-global-types';
import { PROJECTS } from '~types/projects';

export interface PageFormContextType {
  langcode: string;
  path: string;
  headline: string;
  status: number;
  template: Templates;
  project: PROJECTS;
  translations: PageByKey_pageByKey_translations[];
  permanent: number;
  metatags: PageByKey_pageByKey_translations_metatags;
  globalSettingsActive: Boolean;
  fields: PageByKey_pageByKey_translations_fields[];
}

export enum ActionTypes {
  SetLangcode = 'SET_LANGCODE',
  SetMetatagTitle = 'SET_METATAG_TITLE',
  SetMetatagDescription = 'SET_METATAG_DESCRIPTION',
  SetMetatagRobots = 'SET_METATAG_ROBOTS',
  SetMetatags = 'SET_METATAGS',
  SetProject = 'SET_PROJECT',
  SetTemplate = 'SET_TEMPLATE',
  SetConnections = 'SET_CONNECTIONS',
  SetTranslations = 'SET_TRANSLATIONS',
  SetHeadline = 'SET_HEADLINE',
  SetStatus = 'SET_STATUS',
  SetPermanent = 'SET_PERMANENT',
  SetPageProperties = 'SET_PAGE_PROPERTIES',
  SetFields = 'SET_FIELDS',
  ChangeFields = 'CHANGE_FIELDS',
}

type SetLangcodeAction = {
  type: ActionTypes.SetLangcode;
  value: string;
}

type SetHeadlineAction = {
  type: ActionTypes.SetHeadline;
  value: string;
}

type SetMetatagTitleAction = {
  type: ActionTypes.SetMetatagTitle;
  value: string;
}

type SetMetatagDescriptionAction = {
  type: ActionTypes.SetMetatagDescription;
  value: string;
}

type SetMetatagRobotsAction = {
  type: ActionTypes.SetMetatagRobots;
  value: string;
}

type SetTemplateAction = {
  type: ActionTypes.SetTemplate;
  value: Templates;
}

type SetMetatagsAction = {
  type: ActionTypes.SetMetatags;
  value: PageByKey_pageByKey_translations_metatags;
}

type SetStatusAction = {
  type: ActionTypes.SetStatus;
  value: number;
}

type SetProjectAction = {
  type: ActionTypes.SetProject;
  value: PROJECTS;
}

type SetPermanentAction = {
  type: ActionTypes.SetPermanent;
  value: number;
}

type SetPagePropertiesAction = {
  type: ActionTypes.SetPageProperties;
  value: PageByKey_pageByKey_translations;
}

type SetTranslationsAction = {
  type: ActionTypes.SetTranslations;
  value: PageByKey_pageByKey_translations[];
}

type SetFieldsAction = {
  type: ActionTypes.SetFields,
  value: PageByKey_pageByKey_translations_fields,
}

type ChangeFieldsAction = {
  type: ActionTypes.ChangeFields,
  value: PageByKey_pageByKey_translations_fields,
}

export type PageFormActions =
  SetLangcodeAction |
  SetMetatagTitleAction |
  SetMetatagDescriptionAction |
  SetFieldsAction |
  ChangeFieldsAction |
  SetMetatagRobotsAction |
  SetMetatagsAction |
  SetTranslationsAction |
  SetProjectAction |
  SetTemplateAction |
  SetHeadlineAction |
  SetStatusAction |
  SetPagePropertiesAction |
  SetPermanentAction;
