import React, { useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Select, Fieldset,
} from '@flixbus/honeycomb-react';

import { PageFormContext } from '~store/PageForm';
import { ActionTypes } from '~store/PageForm/types';

const CurrentLangcode: React.FC = () => {
  const navigate = useHistory();
  const { key } = useParams();
  const { state: { langcode, translations }, dispatch } = useContext(PageFormContext);

  return (
    <Fieldset>
      <Select
        id="langcodes"
        label="Current langcode"
        value={langcode}
        options={
      translations.map((x) => ({
        value: x.langcode,
        displayValue: x.langcode,
      }))
    }
        onChange={
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const pageKey = key.split('@')[0];
        dispatch({
          type: ActionTypes.SetLangcode,
          value: e.currentTarget.value,
        });
        navigate.push(
          `/page/${pageKey}@${e.currentTarget.value}`,
        );
      }
    }
      />
    </Fieldset>
  );
};

export default CurrentLangcode;
