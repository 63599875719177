export const msalConfig = {
  auth: {
    clientId: '3dca6a0a-cd70-430e-b5da-95f46ade9ee9',
    authority: 'https://login.microsoftonline.com/d8d0ad3e-8bcf-48e9-9bb2-aee17c6c4fd5', // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ['api://3dca6a0a-cd70-430e-b5da-95f46ade9ee9/Gql.Write.All'],
  prompt: 'consent',
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
};
