import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/link-context';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './authConfig';

const httpLink = createHttpLink({
  // eslint-disable-next-line no-undef
  uri: process.env.GRAPHQL_ENDPOINT,
});

const authLink = setContext(async (_, { headers }) => {
  const publicClientApp = new PublicClientApplication(msalConfig);

  const account = publicClientApp.getAllAccounts()[0];

  const accessTokenRequest = {
    scopes: ['api://3dca6a0a-cd70-430e-b5da-95f46ade9ee9/Gql.Write.All'],
    account,
  };

  const token = await publicClientApp.acquireTokenSilent(accessTokenRequest);

  return {
    headers: {
      ...headers,
      Authorization: `Bearer ${token.accessToken}`,
    },
  };
});

const APOLLO_CLIENT = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default APOLLO_CLIENT;
