import {
  PageByKey_pageByKey_translations_metatags,
} from '~graphql/getPageByKey/__generated__/PageByKey';
import { Templates } from '~types/graphql-global-types';

export const LAST_CHANGED_LANGCODE = 'LAST_CHANGED_LANGCODE';

export interface DefaultValuesContextType {
  langcode: string;
  headline: string;
  project: string;
  template: Templates;
  metatags: PageByKey_pageByKey_translations_metatags;
}

export enum ActionTypes {
  SetLangcode = 'SET_LANGCODE',
  SetMetatagTitle = 'SET_METATAG_TITLE',
  SetMetatagDescription = 'SET_METATAG_DESCRIPTION',
  SetMetatagRobots = 'SET_METATAG_ROBOTS',
  SetMetatags = 'SET_METATAGS',
  SetHeadline = 'SET_HEADLINE',
  SetProject = 'SET_PROJECT',
  SetTemplate = 'SET_TEMPLATE',
}

type SetLangcodeAction = {
  type: ActionTypes.SetProject;
  value: string;
}

type SetTemplateAction = {
  type: ActionTypes.SetTemplate;
  value: Templates;
}

type SetProjectAction = {
  type: ActionTypes.SetLangcode;
  value: string;
}

type SetHeadlineAction = {
  type: ActionTypes.SetHeadline;
  value: string;
}

type SetMetatagTitleAction = {
  type: ActionTypes.SetMetatagTitle;
  value: string;
}

type SetMetatagDescriptionAction = {
  type: ActionTypes.SetMetatagDescription;
  value: string;
}

type SetMetatagRobotsAction = {
  type: ActionTypes.SetMetatagRobots;
  value: string;
}

type SetMetatagsAction = {
  type: ActionTypes.SetMetatags;
  value: PageByKey_pageByKey_translations_metatags;
}

export type DefaultValuesActions =
  SetLangcodeAction |
  SetMetatagTitleAction |
  SetMetatagDescriptionAction |
  SetMetatagRobotsAction |
  SetMetatagsAction |
  SetProjectAction |
  SetTemplateAction |
  SetHeadlineAction;
