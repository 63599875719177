import React, { createContext, useReducer, Dispatch } from 'react';

import { Templates } from '~types/graphql-global-types';
import { DefaultValuesContextType, DefaultValuesActions, LAST_CHANGED_LANGCODE } from './types';

import reducer from './reducer';

interface DefaultValuesContextProviderProps {
  children: React.ReactChild | React.ReactChildren,
}

const initialState: DefaultValuesContextType = {
  langcode: sessionStorage.getItem(LAST_CHANGED_LANGCODE) || 'de',
  headline: '',
  project: 'flixbus',
  template: Templates.city,
  metatags: {
    __typename: 'MetatagsPattern',
    description: '',
    robots: '',
    langcode: '',
    title: '',
  },
};

const DefaultValuesContext = createContext<{
  state: DefaultValuesContextType;
  dispatch: Dispatch<DefaultValuesActions>;
  }>({
    state: initialState,
    dispatch: () => null,
  });

const DefaultValuesContextProvider: React.FC = (
  { children }: DefaultValuesContextProviderProps,
) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <DefaultValuesContext.Provider value={{ state, dispatch }}>
      {children}
    </DefaultValuesContext.Provider>
  );
};

const DefaultValuesContextConsumer = DefaultValuesContext.Consumer;

export {
  initialState, DefaultValuesContext, DefaultValuesContextProvider, DefaultValuesContextConsumer,
};
