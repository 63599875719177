import React, { useContext } from 'react';

import {
  Heading, Select, Fieldset,
} from '@flixbus/honeycomb-react';

import { ActionTypes } from '~store/DefaultValues/types';
import { DefaultValuesContext } from '~store/DefaultValues';
import { PROJECTS } from '~types/projects';

const CurrentProject: React.FC = () => {
  const { state: { project }, dispatch } = useContext(DefaultValuesContext);

  return (
    <Fieldset>
      <Heading size={2}>
        Current Project
      </Heading>

      <Select
        id="langcodes"
        label="Current project"
        defaultValue={project}
        options={Object.values(PROJECTS).map((x) => ({
          value: x,
          displayValue: x.toLocaleUpperCase(),
        }))}
        onChange={
          (e: React.ChangeEvent<HTMLInputElement>) => dispatch({
            type: ActionTypes.SetProject,
            value: e.target.value,
          })
        }
      />
    </Fieldset>
  );
};

export default CurrentProject;
